import axios, { AxiosResponse } from "axios";
import { API_ENDPOINT, HEADER_CONFIG } from "../config"
const API_NAME = "Storage";
export class StorageService {
  public getUploadSas = async (accessToken: string, fileName: string): Promise<AxiosResponse> => {
    console.log("waht");
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    return await axios
        .get(API_ENDPOINT + `${API_NAME}/UploadSas?fileName=${fileName}`, HEADER_CONFIG);
  };
}