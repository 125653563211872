import * as React from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import { useId } from '@fluentui/react-hooks';

const dialogStyles = { main: { maxWidth: 450 } };

const dialogContentProps = {
  type: DialogType.normal,
  title: 'File cannot be read',
  closeButtonAriaLabel: 'Close',
  subText: 'Template may be broken or inaccessible',
};

interface IDialogProps {
  isHidden: boolean;
  title: string;
  subText: string;
  onDismiss: () => void;
  onOk: () => void;
};

export const ConfirmDialog: React.FunctionComponent<IDialogProps> = (props) => {
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');

  const contentProps = { ...dialogContentProps, ...props }

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
      styles: dialogStyles,
      dragOptions: undefined,
    }),
    [labelId, subTextId],
  );

  return (
    <Dialog
      hidden={props.isHidden}
      onDismiss={props.onDismiss}
      dialogContentProps={contentProps}
      modalProps={modalProps}
    >
      <DialogFooter>
        <PrimaryButton onClick={props.onOk} text="Yes" />
        <DefaultButton onClick={props.onDismiss} text="No" />
      </DialogFooter>
    </Dialog>
  );
};