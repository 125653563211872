import axios from "axios";
import { API_ENDPOINT, HEADER_CONFIG } from "../config";
import { ITemplateGroup } from "../interfaces/API/ITemplateGroup";
import { ITemplateGroupSearchParams } from '../interfaces/API/ITemplateGroupSearchParams';
import { IOrgTemplateGroupCreate } from "../interfaces/API/IOrgTemplateGroupCreate";

const API_NAME = "OrgTemplateGroup/";
export class OrgTemplateGroupService {
  public getOrgTemplateGroups = async (accessToken: string, params: ITemplateGroupSearchParams = { searchTerm: '' }): Promise<ITemplateGroup[]> => {
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;

    try {
      const response = await axios
        .get(API_ENDPOINT + `${API_NAME}List?q=${params.searchTerm}`, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public postSaveOrgTemplateGroup = async (accessToken: string, body: IOrgTemplateGroupCreate): Promise<ITemplateGroup> => {;
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    
    try {
      const response = await axios
        .post(API_ENDPOINT + `${API_NAME}Save`, body, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public putUpdateOrgTemplateGroup = async (accessToken: string, id: number, body: IOrgTemplateGroupCreate): Promise<ITemplateGroup> => {;
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    
    try {
      const response = await axios
        .put(API_ENDPOINT + `${API_NAME}Update/${id}`, body, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public deleteOrgTemplateGroup = async (accessToken: string, id: number): Promise<void> => {
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    
    try {
      const response = await axios
        .delete(API_ENDPOINT + `${API_NAME}Delete/${id}`, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };
}