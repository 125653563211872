import * as React from "react";

import {
    DetailsList,
    IColumn,
    IDetailsList,
    IGroup,
    SearchBox,
    SelectionMode,
    ThemeProvider,
    createTheme,
    IDetailsRowStyles, IDetailsListProps,
    DetailsRow,
    IDetailsGroupRenderProps,
    GroupHeader,
    IGroupHeaderStyles,
    IconButton,
    IIconProps,
    ActionButton,
    Text,
    IButtonStyles,
    CommandBar,
    ICommandBarItemProps,
    IButtonProps
} from "@fluentui/react";
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { TemplateItem } from '../models/TemplateItem';
import { urlToBase64 } from "../helpers/fileHelper";
import { generateGroups } from "../helpers/listHelper";
import { TemplateService } from "../services/TemplateService";
import Progress from "./shared/Progress";
import Loading from "./shared/Loading";
import { ITemplateSearchParams } from '../interfaces/API/ITemplateSearchParams';
import { TemplateSearchParams } from "../models/TemplateSearchParams";
import { AppState } from "./App";
import { ITemplateItem } from '../interfaces/API/ITemplateItem';
import _ from "lodash";
import { ConfirmDialog } from "./shared/ConfirmDialog";
import { OrgTemplateService } from "../services/OrgTemplateService";

const theme = createTheme({
    // You can also modify certain other properties such as fontWeight if desired
    defaultFontStyle: { fontFamily: 'sans-serif, Segoe UI, Suwannaphum' }
});

const stackTokens: Partial<IStackTokens> = { childrenGap: 5 };

export interface ITemplatesState {
    deletingItem: TemplateItem;
    items: TemplateItem[];
    groups: IGroup[];
    loading: boolean;
    opening: boolean;

    isNewWindow: boolean;

    showConfirmDialog: boolean;
}

export interface ITemplatesProps {
    accessToken: string;
    listType: number;
    createdTemplate?: TemplateItem;
    updatedTemplate?: TemplateItem;
    setState: (x: AppState) => void;
}

const deleteProps = {
    title: "Delete",
    subText: "Are you sure you want to delete this template?"
}

export default class TemplateList extends React.Component<ITemplatesProps, ITemplatesState> {
    private _root = React.createRef<IDetailsList>();
    private _columns: IColumn[];

    constructor(props: ITemplatesProps) {
        super(props);
        this.state = {
            items: [],
            groups: [],

            loading: false,
            opening: false,

            deletingItem: null,
            isNewWindow: false,
            showConfirmDialog: false
        };

        this._columns = [
            { key: 'name', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true }
        ];
    }

    static getDerivedStateFromProps(props: ITemplatesProps, state: ITemplatesState) {
        if (props.updatedTemplate) {
            let oldTemplateIndex = _.findIndex(state.items, (item: TemplateItem) => {
                return item.id === props.updatedTemplate.id;
            });

            if (!_.isEqual(props.updatedTemplate, state.items[oldTemplateIndex])) {
                let items = state.items;
                items[oldTemplateIndex] = props.updatedTemplate;

                props.setState({ editFormData: null, updatedTemplate: null });

                return {
                    items: items,
                    groups: generateGroups(items)
                };
            }
        }

        if (props.createdTemplate) {
            let items = state.items;
            let newArray = [...items, props.createdTemplate];
            
            //Re-order group
            newArray = _.orderBy(newArray, a => a.groupId);

            props.setState({ createdTemplate: null });

            return {
                items: newArray,
                groups: generateGroups(newArray)
            };
        }

        return null;
    }

    componentDidMount(): void {
        this.handleSearch("");
    }

    handleSearch(term: string): void {
        const { listType } = this.props;

        this.setState({ loading: true });

        let templateService = new TemplateService();
        let groups: IGroup[];
        let params: ITemplateSearchParams = new TemplateSearchParams();

        params.searchTerm = term;
        params.listType = listType;

        switch (Office.context.host) {
            case Office.HostType.Excel:
                params.fileType = '.xlsx';
                break;
            case Office.HostType.Word:
                params.fileType = '.docx';
                break;
            case Office.HostType.PowerPoint:
                params.fileType = '.pptx';
                break;
            default:
                throw "Unsupported Office host application: This add-in only runs on Excel, PowerPoint, or Word.";
        }

        templateService.searchTemplates(this.props.accessToken, params).then((data: ITemplateItem[]) => {
            groups = generateGroups(data);

            this.setState({ items: data, groups: groups, loading: false });
        });
    }

    onSearchTemplate = (newValue: any): void => {
        // Office.context.ui.messageParent("You have searched for " + newValue);
        console.log("You have searched for " + newValue);
        this.handleSearch(newValue + "");
    }

    onClearSearchTemplate = (_ev?: any): void => {
        this.handleSearch("");
    }

    onChangeSearchTemplate = (_ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string): void => {
        if (newValue === "") {
            this.handleSearch("");
        }
    }

    onDeleteConfirm = () => {
        const { items, deletingItem: deleteItem } = this.state;

        this.setState({
            showConfirmDialog: false,
            loading: true
        });

        let orgTemplateService = new OrgTemplateService();
        orgTemplateService.deleteOrgTemplate(this.props.accessToken, deleteItem.id).then(() => {
            let deleteTemplateIndex = _.findIndex(items, (item: TemplateItem) => {
                return item.id === deleteItem.id;
            });

            items.splice(deleteTemplateIndex, 1);

            this.setState({
                items: items,
                groups: generateGroups(items),
                loading: false,
                showConfirmDialog: false
            });
        });
    }

    render() {
        const { children } = this.props;
        const { items, groups, showConfirmDialog } = this.state;

        return (
            <Stack tokens={stackTokens}>
                <Stack.Item className="section-search">
                    <ThemeProvider theme={theme}>
                        <SearchBox placeholder="Search templates" onSearch={this.onSearchTemplate} onClear={this.onClearSearchTemplate} />
                    </ThemeProvider>
                </Stack.Item>
                {
                    this.state.opening &&
                    <Loading message="Please wait..." />
                }
                {
                    this.state.loading &&
                    <Progress message="Loading templates" />
                }
                {
                    !this.state.loading &&
                    <React.Fragment>
                        {
                            this.state.items.length > 0 ?
                                <Stack.Item className="section-templates">
                                    <ThemeProvider theme={theme}>
                                        <DetailsList
                                            componentRef={this._root}
                                            items={items}
                                            groups={groups}
                                            columns={this._columns}
                                            groupProps={{
                                                showEmptyGroups: true,
                                                onRenderHeader: this._onRenderGroupHeader
                                            }}
                                            onRenderItemColumn={(item, index, column) => this._onRenderItemColumn(item, index, column, this.onClick)}
                                            onRenderRow={this._onRenderRow}
                                            compact={true}
                                            isHeaderVisible={false}
                                            selectionMode={SelectionMode.none}
                                        />
                                    </ThemeProvider>
                                    {children}
                                </Stack.Item>
                                :
                                <Stack.Item align="center">
                                    <Stack tokens={{ childrenGap: 10 }}>
                                        <Text>
                                            No result found 🤷‍♀️
                                        </Text>
                                    </Stack>
                                </Stack.Item>
                        }
                    </React.Fragment>
                }
                <ConfirmDialog
                    {...deleteProps}
                    onOk={this.onDeleteConfirm}
                    isHidden={!showConfirmDialog}
                    onDismiss={() => this.setState({ showConfirmDialog: !showConfirmDialog })} />
            </Stack>
        );
    }

    private onClick = async (templateItem: TemplateItem, isNewWindow: boolean = false, isReplace: boolean = false, isDelete = false) => {
        this.setState({ isNewWindow: isNewWindow });

        if (isReplace) {
            console.log("Do replace", templateItem);
            this.props.setState({ editFormData: templateItem, hideUploadDialog: false });
            return;
        }

        if (isDelete) {
            console.log("Delete");
            this.setState({ showConfirmDialog: true });
            return;
        }

        // let docUrl = "https://sktemplatestorage.blob.core.windows.net/public-blobs/Sample.docx";

        this.renderDocument(templateItem, isNewWindow);
    };

    private onDeleteClick = (templateItem: TemplateItem) => {
        this.setState({ deletingItem: templateItem });
        
        console.log("Delete");
        this.setState({ showConfirmDialog: true });
        return;
    };

    private onToggleEnableClick = (templateItem: TemplateItem) => {        
        console.log("Do update status", templateItem);
        let orgTemplateService = new OrgTemplateService();
        orgTemplateService.patchUpdateOrgTemplateStatus(this.props.accessToken, templateItem.id, { isActive: !templateItem.isActive }).then(res => {
            let items = this.state.items;

            let oldTemplateIndex = _.findIndex(items, (item: TemplateItem) => {
                return item.id === res.id;
            });

            if (!_.isEqual(res, items[oldTemplateIndex])) {
                items[oldTemplateIndex] = res;

                this.setState({
                    items: items,
                    groups: generateGroups(items)
                });
            }
        });
    };

    private renderDocument = (templateItem: TemplateItem, isNewWindow: boolean = false) => {
        this.setState({ opening: true });
        try {
            urlToBase64(`${templateItem.url}?v=${Date.now()}`, (base64file: string) => {
                this.setState({ opening: false });

                return Word.run(async (context) => {

                    if (isNewWindow) {
                        let newDoc = context.application.createDocument(base64file);
                        // newDoc.settings.add("Office.AutoShowTaskpaneWithDocument", true);
                        // newDoc.context.document.settings.s;
                        // newDoc.settings.load();
                        // console.log(newDoc.settings);
                        // const ooxml = newDoc.body.getOoxml();
                        //     await context.sync().then(async () => {                                
                        //         const parser = new DOMParser();
                        //         const xmlDoc = parser.parseFromString(ooxml.value, "text/xml");

                        //         const serializedXML = this.replaceTextInObject(xmlDoc);
                        //         newDoc.body.clear();

                        //         await context.sync().then(() => {
                        //             newDoc.body.insertOoxml(serializedXML, Word.InsertLocation.replace);
                        //         })
                        //     });
                        newDoc.open();
                    } else {
                        // let doc = context.application.createDocument(base64file);
                        // Office.context.document.settings.set("Office.AutoShowTaskpaneWithDocument", true);
                        // Office.context.document.settings.saveAsync();
                        context.document.body.insertFileFromBase64(base64file, Word.InsertLocation.end);
                    }

                    await context.sync();
                });
            });
        } catch (error) {
            console.log(error);
            this.props.setState({ showMessageDialog: true, dialogErrorTitle: 'Cannot open template', dialogErrorMessage: 'The template is broken or inaccessible' });
        } finally {
            this.setState({ opening: false });
        }
    }

    private _onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = props => {
        const customStyles: Partial<IGroupHeaderStyles> = {};
        if (props) {
            customStyles.root = {
                color: theme.palette.blue,
                '&:hover': {
                    color: theme.palette.blueMid
                }
            };
            return (
                <GroupHeader {...props} styles={customStyles} />
            );
        }

        return null;
    };

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            // if (props.itemIndex % 2 === 0) {
            //     // Every other row renders with a different background color
            //     customStyles.root = { backgroundColor: theme.palette.themeLighterAlt, borderBottom: `1px solid ${theme.palette.themeLight}`};
            //     customStyles.root = { borderBottom: `1px solid ${theme.palette.themeLighterAlt}` };
            // }

            customStyles.root = {
                // '&>span': {
                //     width: '5px !important',
                // },
                '& .ms-DetailsRow-cell': {
                    // whiteSpace: 'normal',
                    // cursor: 'pointer'
                    position: 'initial'
                },
                '& .ms-CommandBar':{
                    backgroundColor: 'inherit',
                    padding: 0,
                    '& .ms-Button':{
                        backgroundColor: 'inherit'
                    }
                }
            }

            if (!props.item.isActive) {
                customStyles.root = { 
                    ...customStyles.root, 
                    backgroundColor: theme.palette.neutralLight
                };
            }

            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };

    private _items: ICommandBarItemProps[] = [];

    private _overflowItems = (item: TemplateItem) => {
        let itemProps: ICommandBarItemProps[] = [
            { key: 'delete', text: 'Delete', onClick: () => this.onDeleteClick(item), iconProps: { iconName: 'Delete' } },
            { key: item.isActive ? 'hide' : 'show', text: item.isActive ? 'Deactivate' : 'Activate', onClick: () => this.onToggleEnableClick(item), iconProps: { iconName: item.isActive ? 'Hide' : 'RedEye' } },
        ]
        return itemProps;
    };

    private _onRenderItemColumn(item: TemplateItem, index: number, column: IColumn, clickEv: any) {
        const value = (item && column && column.fieldName) ? item[column.fieldName as keyof TemplateItem] || '' : '';
        const backIcon: IIconProps = { iconName: 'ReplyAlt' };

        const loadButtonStyles: Partial<IButtonStyles> = {};
        const openButtonStyles: Partial<IButtonStyles> = {};

        const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

        loadButtonStyles.root = {
            position: 'absolute',
            left: '3px'
        };

        openButtonStyles.root = {
            fontSize: '90%',
            color: theme.palette.blue
        };

        switch (column.key) {
            case "name": return <Stack key={index} horizontal verticalAlign='center' styles={{ root: { position: 'inherit' } }}>
                <IconButton styles={loadButtonStyles}
                    onClick={() => clickEv(item)}
                    iconProps={backIcon}
                    title="Load in this window"
                    ariaLabel="Load"
                    data-is-focusable={false} />
                <Text styles={{ root: { whiteSpace: 'normal', fontSize: 'inherit', flexGrow: 1 } }}>
                    {value}
                    <span title="Owned by your organization">{item.isCustomShared ? " *" : ""}</span>
                </Text>
                {/* <ActionButton
                    onClick={() => clickEv(item, false, false, true)}
                    styles={openButtonStyles}
                    text=""
                    iconProps={{ iconName: 'Delete' }}
                    title="Delete this template forever" ariaLabel="Delete" /> */}
                <ActionButton
                    onClick={() => clickEv(item, false, true)}
                    styles={openButtonStyles}
                    text=""
                    iconProps={{ iconName: 'Sync' }}
                    title="Replace template with current document" ariaLabel="Replace" />
                <ActionButton
                    onClick={() => clickEv(item, true)}
                    styles={openButtonStyles}
                    text=""
                    iconProps={{ iconName: 'OpenInNewWindow' }}
                    title="Open in new window" ariaLabel="Open" />
                <CommandBar
                    items={this._items}
                    overflowItems={this._overflowItems(item)}
                    overflowButtonProps={overflowProps}
                    ariaLabel="More actions"
                />
            </Stack>;
        }

        return <div key={index} data-is-focusable={true}>{value}</div>;
    }
}
