import * as React from 'react';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { DefaultButton, IconButton, IPersonaSharedProps, IStackTokens, nullRender, Persona, PersonaPresence, PersonaSize, Stack } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import { BlobServiceClient, BlockBlobClient } from '@azure/storage-blob';
import { StorageService } from '../services/StorageService';
import { MessageDialog } from './shared/MessageDialog';
import App, { AppState } from './App';

export interface MenuProps {
  userInfo: any;
  logout: () => {};
  accessToken: string;
  setState: (x: AppState) => void;
}

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 0,
  padding: 7,
};

export const Menu: React.FunctionComponent<MenuProps> = (props) => {
  const { userInfo, logout } = props;

  const menuProps = useConst<IContextualMenuProps>(() => ({
    isBeakVisible: false,
    shouldFocusOnMount: true,
    items: [
      { key: 'signout', text: 'Sign Out', iconProps: { iconName: 'SignOut' }, onClick: () => { logout() } },
    ],
  }));

  const onUpload = () => {
    console.log('Upload');
    props.setState({ hideUploadDialog: false });
  }

  return (
    <Stack horizontal={true} horizontalAlign='space-between' tokens={itemAlignmentsStackTokens}>
      <Stack.Item tokens={{ margin: '0px 0px 0px 5px' }} align='center'>
        <Persona
          imageUrl=''
          text={userInfo.name}
          secondaryText={userInfo.unique_name}
          presence={PersonaPresence.none}
          size={PersonaSize.size24}
          hidePersonaDetails={false}
          imageAlt={userInfo.name}
          showSecondaryText={true}
        />
      </Stack.Item>
      <Stack.Item>
        <IconButton iconProps={{ iconName: 'Upload' }} onClick={onUpload} title="Upload current document" />
      </Stack.Item>
      <Stack.Item>
        <IconButton iconProps={{ iconName: 'GlobalNavButton' }} title="Menu" ariaLabel="Menu" menuProps={menuProps} onRenderMenuIcon={nullRender} />
      </Stack.Item>
    </Stack>
  );
};