import * as React from 'react';
import { createTheme, Pivot, PivotItem } from '@fluentui/react';
import TemplateList from './TemplateList';
import { ListTypes, TabHeaderKeyType } from '../models/Enums';
import App, { AppState } from './App';
import { TemplateItem } from '../models/TemplateItem';
import GroupList from './GroupList';
import { TemplateGroup } from '../models/TemplateGroup';

export interface GetDataPageBodyProps {
    accessToken: string;
    setState: (x: AppState) => void;
    createdTemplate?: TemplateItem;
    updatedTemplate?: TemplateItem;
}

export interface GetDataPageBodyState {
    listType?: number;
}

const theme = createTheme({
    // You can also modify certain other properties such as fontWeight if desired
    defaultFontStyle: { fontFamily: 'sans-serif, Segoe UI, Suwannaphum' }
});

export default class GetDataPageBody extends React.Component<GetDataPageBodyProps, GetDataPageBodyState> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            listType: ListTypes.ORG,
        };
    }

    onChangePivot = (item?: PivotItem) => {
        switch (item.props.headerText) {
            case TabHeaderKeyType.ORG_TEMPLATES:
                this.setState({ listType: ListTypes.ORG });
                break;
            case TabHeaderKeyType.GLOBAL_TEMPLATES:
                this.setState({ listType: ListTypes.GLOBAL });
                break;
        }
    }

    renderOrgPivotItems = () => {
        const { accessToken } = this.props;
        const { listType } = this.state;
        return (
            <Pivot aria-label="Skai Template menu" onLinkClick={this.onChangePivot} styles={{
                root: {
                    "& .ms-Pivot-icon": {
                        color: theme.palette.themePrimary
                    }
                }
            }}>
                <PivotItem headerText="Org Templates" >
                    {
                        listType === ListTypes.ORG &&
                        <TemplateList
                            setState={this.props.setState}
                            accessToken={accessToken}
                            listType={ListTypes.ORG}
                            createdTemplate={this.props.createdTemplate}
                            updatedTemplate={this.props.updatedTemplate}
                        ></TemplateList>
                    }
                </PivotItem>
                <PivotItem headerText="Org Template Groups">
                    <GroupList 
                    setState={this.props.setState} 
                    accessToken={accessToken}
                    ></GroupList>
                </PivotItem>
            </Pivot>
        )
    }

    renderGlobalPivotItems = () => {

    }

    render() {
        return (
            <div>
                {
                    this.renderOrgPivotItems()
                }
            </div>
        );
    }
}
