import axios from "axios";
import { API_ENDPOINT, HEADER_CONFIG } from "../config";
import { IOrgTemplateCreate } from "../interfaces/API/IOrgTemplateCreate";
import { ITemplateItem } from '../interfaces/API/ITemplateItem';
import { IOrgTemplatePatch } from '../interfaces/API/IOrgTemplatePatch';

const API_NAME = "OrgTemplate/";
export class OrgTemplateService {

  public postSaveOrgTemplate = async (accessToken: string, body: IOrgTemplateCreate): Promise<ITemplateItem> => {;
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    
    try {
      const response = await axios
        .post(API_ENDPOINT + `${API_NAME}SaveOrgTemplate`, body, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public putUpdateOrgTemplate = async (accessToken: string, id: number, body: IOrgTemplateCreate): Promise<ITemplateItem> => {;
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    
    try {
      const response = await axios
        .put(API_ENDPOINT + `${API_NAME}UpdateOrgTemplate/${id}`, body, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public patchUpdateOrgTemplateStatus = async (accessToken: string, id: number, body: IOrgTemplatePatch): Promise<ITemplateItem> => {;
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    
    try {
      const response = await axios
        .patch(API_ENDPOINT + `${API_NAME}UpdateOrgTemplateStatus/${id}`, body, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public deleteOrgTemplate = async (accessToken: string, id: number): Promise<void> => {
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    
    try {
      const response = await axios
        .delete(API_ENDPOINT + `${API_NAME}DeleteOrgTemplate/${id}`, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };
}