import { DefaultButton } from '@fluentui/react';
import * as React from 'react';
import Header from './Header';

export interface StartPageBodyProps {
    title: string;
    login: () => {};
}

export default class StartPageBody extends React.Component<StartPageBodyProps> {
    render() {
        const { title, login } = this.props;
        const headerMessage: string = "Welcome to Skai Template Admin";
        const subText: string = "Upload and edit template quickly";

        return (
            <div className='sk-template'>
                <div className='sk-template__main'>                    
                    <Header logo='assets/logo-filled.png' title={title} message={headerMessage} />
                    <h3 className='ms-fontSize-md ms-fontWeight-light ms-fontColor-neutralPrimary'>
                        {subText}
                    </h3>
                    <DefaultButton className='ms-sk-template__action' onClick={login} styles={{ root: { height: '41px' } }}>
                        <object title='Microsoft' type="image/svg+xml" data="/assets/ms-symbollockup_mssymbol_19.svg" className='x-icon'></object>
                        Sign in with Microsoft
                    </DefaultButton>
                </div>
            </div>
        );
    }
}
