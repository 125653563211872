import { AppState } from '../src/taskpane/components/App';
import { InteractionRequiredAuthError, PublicClientApplication, SsoSilentRequest } from '@azure/msal-browser';
import { API_SCOPES, APP_ID, APP_URL, DOMAIN } from '../src/taskpane/config';

const msalInstance = new PublicClientApplication({
    auth: {
        clientId: APP_ID,
        authority: 'https://login.microsoftonline.com/organizations',
        redirectUri: APP_URL + 'logoutcomplete/logoutcomplete.html' // Must be registered as "spa" type
    },
    cache: {
        cacheLocation: 'localStorage', // needed to avoid "login required" error
        storeAuthStateInCookie: true   // recommended to avoid certain IE/Edge issues
    }
});

export const signInSilentO365 = async (setState: (x: AppState) => void,
    setToken: (x: string) => void,
    displayError: (x: string) => void) => {

    // let loginHint = localStorage.getItem("loginHint");
    // let domainHint = localStorage.getItem("domainHint");
    let request: SsoSilentRequest = {
        scopes: API_SCOPES,
        // loginHint: loginHint,
        domainHint: DOMAIN
    };

    msalInstance.ssoSilent(request).then((response) => {
        // console.log(response);
        setToken(response.accessToken);
        setState({
            authStatus: 'loggedIn'
        });
    }).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            console.log(error);
            await signInO365(setState, setToken, displayError);
        }
    }).catch(error => {
        console.log(error);
    });
};

/*
    Managing the dialogs.
*/

let loginDialog: Office.Dialog;
const dialogLoginUrl: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/login/login.html';

export const signInO365 = async (setState: (x: AppState) => void,
    setToken: (x: string) => void,
    displayError: (x: string) => void) => {

    setState({ authStatus: 'loginInProcess' });

    // let loginHint = localStorage.getItem("loginHint");
    let domainHint = DOMAIN;

    try {
        Office.context.ui.displayDialogAsync(
            //`${dialogLoginUrl}?loginHint=${loginHint}&domainHint=${domainHint}`,
            `${dialogLoginUrl}?domainHint=${domainHint}`,
            { height: 40, width: 30 },
            (result) => {
                if (result.status === Office.AsyncResultStatus.Failed) {
                    displayError(`${result.error.code} ${result.error.message}`);
                }
                else {
                    loginDialog = result.value;
                    loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLoginMessage);
                    loginDialog.addEventHandler(Office.EventType.DialogEventReceived, processLoginDialogEvent);
                }
            },
        )
    } catch (error) {
        console.error(error);
        setState({ authStatus: 'notLoggedIn' });
    }

    const processLoginMessage = (arg: { message: string, origin: string }) => {

        let messageFromDialog = JSON.parse(arg.message);
        if (messageFromDialog.status === 'success') {

            // We now have a valid access token.
            loginDialog.close();

            setToken(messageFromDialog.result.accessToken);
            setState({
                authStatus: 'loggedIn',
                headerMessage: 'Get Data'
            });
        }
        else {
            // Something went wrong with authentication or the authorization of the web application.
            loginDialog.close();

            //Currently we don't show error to user, so we show as not logged in
            setState({
                authStatus: 'notLoggedIn',
                headerMessage: 'Welcome'
            });

            console.log(messageFromDialog.result);

            displayError("Please contact your administrator.");
        }
    };

    const processLoginDialogEvent = (arg) => {
        processDialogEvent(arg, setState, displayError);
    };
};

let logoutDialog: Office.Dialog;
const dialogLogoutUrl: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/logout/logout.html';

export const logoutFromO365 = async (setState: (x: AppState) => void,
    displayError: (x: string) => void) => {

    let loginHint = localStorage.getItem("loginHint");

    try {
        Office.context.ui.displayDialogAsync(`${dialogLogoutUrl}?loginHint=${loginHint}`,
            { height: 40, width: 30 },
            (result) => {
                if (result.status === Office.AsyncResultStatus.Failed) {
                    displayError(`${result.error.code} ${result.error.message}`);
                }
                else {
                    logoutDialog = result.value;
                    logoutDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLogoutMessage);
                    logoutDialog.addEventHandler(Office.EventType.DialogEventReceived, processLogoutDialogEvent);
                }
            }
        );
    } catch (error) {
        console.error(error);
        setState({ authStatus: 'notLoggedIn' });
    }

    const processLogoutMessage = (arg: { message: string, origin: string }) => {
        console.log(arg);
        logoutDialog.close();
        setState({
            authStatus: 'notLoggedIn',
            headerMessage: 'Welcome'
        });
    };

    const processLogoutDialogEvent = (arg) => {
        processDialogEvent(arg, setState, displayError);
    };
};

const processDialogEvent = (arg: { error: number, type: string },
    setState: (x: AppState) => void,
    displayError: (x: string) => void) => {

    switch (arg.error) {
        case 12002:
            displayError('The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.');
            break;
        case 12003:
            displayError('The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.');
            break;
        case 12006:
            // 12006 means that the user closed the dialog instead of waiting for it to close.
            // It is not known if the user completed the login or logout, so assume the user is
            // logged out and revert to the app's starting state. It does no harm for a user to
            // press the login button again even if the user is logged in.
            setState({
                authStatus: 'notLoggedIn',
                headerMessage: 'Welcome'
            });
            break;
        default:
            displayError('Unknown error in dialog box.');
            break;
    }
};

