import * as React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import jwtDecode from "jwt-decode";
import Progress from "./shared/Progress";
import { logoutFromO365, signInO365, signInSilentO365 } from "../../../utilities/office-apis-helpers";
import { Menu } from './Menu';
import StartPageBody from "./StartPageBody";
import GetDataPageBody from "./GetDataPageBody";
import ErrorPageBody from "./ErrorPageBody";
import _ from "lodash";
import { MessageDialog } from "./shared/MessageDialog";
import { UploadTemplateDialog } from "./UploadTemplateDialog";
import Loading from "./shared/Loading";
import { TemplateItem } from "../models/TemplateItem";
import { TemplateGroup } from "../models/TemplateGroup";
import { ITemplateGroup } from "../interfaces/API/ITemplateGroup";
import { CreateTemplateGroupDialog } from "./CreateTemplateGroupDialog";

/* global Word, require */

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface AppState {
  headerMessage?: string;
  errorMessage?: string;
  authStatus?: string;
  userInfo?: any;
  
  inProgress?: boolean;

  showMessageDialog?: boolean;
  dialogErrorTitle?: string;
  dialogErrorMessage?: string;

  hideUploadDialog?: boolean;
  editFormData?: TemplateItem;
  updatedTemplate?: TemplateItem;
  createdTemplate?: TemplateItem;
}

export default class App extends React.Component<AppProps, AppState> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      authStatus: 'notLoggedIn',
      headerMessage: 'Welcome to Skai Template Admin',
      showMessageDialog: false,
      dialogErrorTitle: 'Error',
      dialogErrorMessage: '',
      errorMessage: '',

      hideUploadDialog: true,
      inProgress: false,
    };

    this.boundSetState = this.setState.bind(this);
    this.setToken = this.setToken.bind(this);
    // this.getToken = this.getToken.bind(this);
    this.displayError = this.displayError.bind(this);
    this.login = this.login.bind(this);
  }

  accessToken: string;

  boundSetState: () => {};

  setToken = (accessToken: string) => {
    this.accessToken = accessToken;

    let userToken = jwtDecode(this.accessToken) as any;

    localStorage.setItem("domainHint", userToken.tid);
    // localStorage.setItem("loginHint", userToken.upn);

    this.setState({ userInfo: userToken });

    // console.log(userToken);
  }

  displayError = (error: string) => {
    this.setState({ errorMessage: error });
  }

  // Runs when the user clicks the X to close the message bar where
  // the error appears.
  errorDismissed = () => {
    this.setState({ errorMessage: '' });

    this.setState((prevState) => {
      if (prevState.authStatus === 'loginInProcess') {
        return { authStatus: 'notLoggedIn' };
      }
      return null;
    });
  }

  login = async () => {
    await signInO365(this.boundSetState, this.setToken, this.displayError);
  }

  logout = async () => {
    await logoutFromO365(this.boundSetState, this.displayError);
  }

  async componentDidMount() {
    this.authenticateUser();
  }

  async authenticateUser() {
    try {
      // let userTokenEncoded = await OfficeRuntime.auth.getAccessToken({
      //   allowConsentPrompt: true,
      //   allowSignInPrompt: true,
      //   forMSGraphAccess: true
      // });

      // let userToken = jwtDecode(userTokenEncoded) as any; // Using the https://www.npmjs.com/package/jwt-decode library.
      // console.log(userToken);

      // localStorage.setItem("loginHint", userToken.upn);
      // localStorage.setItem("domainHint", userToken.tid);

      //Get token to call API
      await signInSilentO365(this.boundSetState, this.setToken, this.displayError);
    }
    catch (exception) {
      console.log(exception);
      if (exception.code === 13003) {
        // SSO is not supported for domain user accounts, only
        // Microsoft 365 Education or work account.
        this.displayError("Please sign in with Microsoft 365 Education or work account");
      } else {
        // Handle error
        this.login();
      }
    }
  }

  private renderBody = () => {
    const { title, isOfficeInitialized } = this.props;
    const { 
      hideUploadDialog, editFormData, updatedTemplate, createdTemplate, 
      showMessageDialog, dialogErrorTitle, dialogErrorMessage 
    } = this.state;

    if (!isOfficeInitialized) {
      return (
        <Progress
          message="Please sideload your addin to see app body."
        />
      );
    }

    if (this.state.errorMessage) {
      return (<ErrorPageBody message={this.state.errorMessage} setState={this.boundSetState} />);
    }

    // Set the body of the page based on where the user is in the workflow.
    let body;

    if (this.state.authStatus === 'initializing') {
      body = (<Progress message="Initializing" />);
    } else if (this.state.authStatus === 'notLoggedIn') {
      body = (<StartPageBody title={title} login={this.login} />);
    } else if (this.state.authStatus === 'loginInProcess') {
      body = (<Spinner className='spinner' size={SpinnerSize.large} label='Please sign-in on the pop-up window.' />);
    } else {
      body = (
        <div>
          {
            this.state.inProgress &&
            <Loading message="Working..." />
          }
          <Menu setState={this.boundSetState} accessToken={this.accessToken} userInfo={this.state.userInfo} logout={this.logout} />
          <GetDataPageBody
            setState={this.boundSetState}
            accessToken={this.accessToken}
            createdTemplate={createdTemplate}
            updatedTemplate={updatedTemplate} />
          <UploadTemplateDialog
            setState={this.boundSetState}
            accessToken={this.accessToken}
            hideDialog={hideUploadDialog}
            editFormData={editFormData}
            toggleHideDialog={() => this.setState({ hideUploadDialog: !hideUploadDialog, editFormData: null })} />          
          <MessageDialog
            title={dialogErrorTitle}
            subText={dialogErrorMessage}
            isHidden={!showMessageDialog}
            onDismiss={() => this.setState({ showMessageDialog: !showMessageDialog })} />
        </div>
      );
    }

    return body;
  }

  render() {
    return (
      <div>
        {this.renderBody()}
      </div>
    );
  }
}
