import * as React from "react";

import {
    DetailsList,
    IColumn,
    IDetailsList,
    SearchBox,
    SelectionMode,
    ThemeProvider,
    createTheme,
    IDetailsRowStyles, IDetailsListProps,
    DetailsRow,
    IIconProps,
    ActionButton,
    Text,
    IButtonStyles,
    CommandButton,
    DefaultButton
} from "@fluentui/react";
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { TemplateItem } from '../models/TemplateItem';
import { TemplateService } from "../services/TemplateService";
import Progress from "./shared/Progress";
import { AppState } from "./App";
import _ from "lodash";
import { ConfirmDialog } from "./shared/ConfirmDialog";
import { ITemplateGroup } from "../interfaces/API/ITemplateGroup";
import { ITemplateGroupSearchParams } from "../interfaces/API/ITemplateGroupSearchParams";
import { CreateTemplateGroupDialog } from "./CreateTemplateGroupDialog";
import { PrimaryButton } from '@fluentui/react';
import ReloadButton from "./shared/ReloadButton";
import { OrgTemplateGroupService } from "../services/OrgTemplateGroupService";

const theme = createTheme({
    // You can also modify certain other properties such as fontWeight if desired
    defaultFontStyle: { fontFamily: 'sans-serif, Segoe UI, Suwannaphum' }
});

const stackTokens: Partial<IStackTokens> = { childrenGap: 5 };

export interface ITemplateGroupsState {
    item?: ITemplateGroup;
    items?: ITemplateGroup[];
    loading?: boolean;

    showConfirmDialog?: boolean;

    hideTemplateGroupDialog?: boolean;
    editingTemplateGroup?: ITemplateGroup;
    updatedTemplateGroup?: ITemplateGroup;
}

export interface ITemplateGroupsProps {
    accessToken: string;
    updatedTemplateGroup?: ITemplateGroup;
    setState: (x: AppState) => void;
}

const deleteProps = {
    title: "Delete",
    subText: "Are you sure you want to delete this template group?"
}

const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 10,    
};

export default class GroupList extends React.Component<ITemplateGroupsProps, ITemplateGroupsState> {
    private _root = React.createRef<IDetailsList>();
    private _columns: IColumn[];

    constructor(props: ITemplateGroupsProps) {
        super(props);
        this.state = {
            items: [],
            loading: false,

            item: null,
            showConfirmDialog: false,
            hideTemplateGroupDialog: true
        };

        this._columns = [
            { key: 'name', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true }
        ];

        this.boundSetState = this.setState.bind(this);
    }

    boundSetState: () => {};

    //Update single updated item in the list
    // static getDerivedStateFromProps(props: ITemplateGroupsProps, state: ITemplateGroupsState) {
    //     if (props.updatedTemplateGroup) {
    //         let oldTemplateIndex = _.findIndex(state.items, (item: ITemplateGroup) => {
    //             return item.id === props.updatedTemplateGroup.id;
    //         });

    //         if (!_.isEqual(props.updatedTemplateGroup, state.items[oldTemplateIndex])) {
    //             let items = state.items;
    //             items[oldTemplateIndex] = props.updatedTemplateGroup;

    //             props.setState({ editingTemplateGroup: null });

    //             return {
    //                 items: items
    //             };
    //         }
    //     }
    //     return null;
    // }

    componentDidMount(): void {
        this.handleSearch("");
    }

    handleSearch(term: string): void {
        this.setState({ loading: true });

        let orgTemplateService = new OrgTemplateGroupService();
        let params: ITemplateGroupSearchParams = {
            searchTerm: term
        }

        orgTemplateService.getOrgTemplateGroups(this.props.accessToken, params).then((data: ITemplateGroup[]) => {
            this.setState({ items: data, loading: false });
        });
    }

    onSearchTemplate = (newValue: any): void => {
        console.log("You have searched for " + newValue);
        this.handleSearch(newValue + "");
    }

    onClearSearchTemplate = (_ev?: any): void => {
        this.handleSearch("");
    }

    onChangeSearchTemplate = (_ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string): void => {
        if (newValue === "") {
            this.handleSearch("");
        }
    }

    // onClickOpenDialog = () => {
    //     this.setState({ showTemplateGroupDialog: !(this.state.showTemplateGroupDialog) });
    // }

    onDeleteConfirm = () => {
        const { items, item: deleteItem } = this.state;

        this.setState({
            showConfirmDialog: false,
            loading: true
        });

        let orgTemplateService = new OrgTemplateGroupService();
        orgTemplateService.deleteOrgTemplateGroup(this.props.accessToken, deleteItem.id).then(() => {
            let deleteTemplateIndex = _.findIndex(items, (item: ITemplateGroup) => {
                return item.id === deleteItem.id;
            });

            items.splice(deleteTemplateIndex, 1);

            this.setState({
                items: items,
                loading: false,
                showConfirmDialog: false
            });
        });
    }

    updateList = (templateGroup: ITemplateGroup, isUpdate: boolean) => {
        const { items } = this.state;

        if(isUpdate){
            let oldTemplateIndex = _.findIndex(items, (item: ITemplateGroup) => {
                return item.id === templateGroup.id;
            });
    
            if (!_.isEqual(templateGroup, items[oldTemplateIndex])) {
                let newArray = [...items];
                newArray[oldTemplateIndex] = templateGroup;
    
                this.setState({ editingTemplateGroup: null, items: [...newArray] });
    
                //this.setState({ updatedTemplateGroup: updatedTemplateGroup });
            }
        }else{
            let newArray = [...items, templateGroup];

            newArray = _.sortBy(newArray, (tg)=> tg.name);

            this.setState({ 
                items: [...newArray] 
            });
        }        
    }

    render() {
        const { children } = this.props;
        const { items, showConfirmDialog, hideTemplateGroupDialog, editingTemplateGroup } = this.state;

        return (
            <Stack tokens={stackTokens}>
                <Stack.Item className="section-search">
                    <ThemeProvider theme={theme}>
                        <Stack horizontal tokens={horizontalGapStackTokens}>
                            <SearchBox placeholder="Search template groups" onSearch={this.onSearchTemplate} onClear={this.onClearSearchTemplate} />
                            <PrimaryButton
                                onClick={() => this.setState({ hideTemplateGroupDialog: false })}
                                text="New"
                                title="Create new template group" ariaLabel="New" />
                        </Stack>
                    </ThemeProvider>
                </Stack.Item>
                {
                    this.state.loading &&
                    <Progress message="Loading template groups" />
                }
                {
                    !this.state.loading &&
                    <React.Fragment>
                        {
                            this.state.items.length > 0 ?
                                <Stack.Item className="section-templates">
                                    <ThemeProvider theme={theme}>
                                        <DetailsList
                                            componentRef={this._root}
                                            items={items}
                                            columns={this._columns}
                                            onRenderItemColumn={(item, index, column) => this._onRenderItemColumn(item, index, column, this.onClick)}
                                            onRenderRow={this._onRenderRow}
                                            compact={true}
                                            isHeaderVisible={false}
                                            selectionMode={SelectionMode.none}
                                        />
                                    </ThemeProvider>
                                    {children}
                                </Stack.Item>
                                :
                                <Stack.Item align="center">
                                    <Stack tokens={{ childrenGap: 10 }}>
                                        <Text>
                                            No result found 🤷‍♀️
                                        </Text>
                                    </Stack>
                                </Stack.Item>
                        }
                    </React.Fragment>
                }
                <CreateTemplateGroupDialog
                    appState={this.props.setState}
                    setState={this.boundSetState}
                    accessToken={this.props.accessToken}
                    hideDialog={hideTemplateGroupDialog}
                    editingTemplateGroup={editingTemplateGroup}
                    updateList={this.updateList}
                    toggleHideDialog={() => this.setState({ hideTemplateGroupDialog: !hideTemplateGroupDialog })} />
                <ConfirmDialog
                    {...deleteProps}
                    onOk={this.onDeleteConfirm}
                    isHidden={!showConfirmDialog}
                    onDismiss={() => this.setState({ showConfirmDialog: !showConfirmDialog })} />
            </Stack>
        );
    }

    private onClick = async (templateGroup: ITemplateGroup, isEdit: boolean = false, isDelete = false) => {
        this.setState({ item: templateGroup });
        // console.log(item);

        if (isEdit) {
            console.log("Edit", templateGroup);
            this.setState({ editingTemplateGroup: templateGroup, hideTemplateGroupDialog: false });
            return;
        }

        if (isDelete) {
            console.log("Delete");
            this.setState({ showConfirmDialog: true });
            return;
        }
    };

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            if (props.itemIndex % 2 === 0) {
                // Every other row renders with a different background color
                //customStyles.root = { backgroundColor: theme.palette.themeLighterAlt, borderBottom: `1px solid ${theme.palette.themeLight}`};
                //customStyles.root = { borderBottom: `1px solid ${theme.palette.themeLighterAlt}` };
            }

            customStyles.root = {
                '& .ms-DetailsRow-cell': {
                    position: 'initial'
                }
            }

            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };

    private _onRenderItemColumn(item: TemplateItem, index: number, column: IColumn, clickEv: any) {
        const value = (item && column && column.fieldName) ? item[column.fieldName as keyof TemplateItem] || '' : '';
        const backIcon: IIconProps = { iconName: 'ReplyAlt' };

        const loadButtonStyles: Partial<IButtonStyles> = {};
        const openButtonStyles: Partial<IButtonStyles> = {};

        loadButtonStyles.root = {
            position: 'absolute',
            left: '3px'
        };

        openButtonStyles.root = {
            fontSize: '90%',
            color: theme.palette.blue
        };

        switch (column.key) {
            case "name": return <Stack key={index} horizontal verticalAlign='center' styles={{ root: { position: 'inherit' } }}>
                <Text styles={{ root: { whiteSpace: 'normal', fontSize: 'inherit', flexGrow: 1 } }}>
                    {value}
                </Text>
                <ActionButton
                    onClick={() => clickEv(item, false, true)}
                    styles={openButtonStyles}
                    text=""
                    iconProps={{ iconName: 'Delete' }}
                    title="Delete this template group" ariaLabel="Delete" />
                <ActionButton
                    onClick={() => clickEv(item, true, false)}
                    styles={openButtonStyles}
                    text=""
                    iconProps={{ iconName: 'Edit' }}
                    title="Edit this template group" ariaLabel="Edit" />
            </Stack>;
        }

        return <div key={index} data-is-focusable={true}>{value}</div>;
    }
}
