import axios from "axios";
import { API_ENDPOINT, HEADER_CONFIG } from "../config";
import { IMalaApiResponse } from '../interfaces/API/IMalaApiResponse';
import { ITemplateSearchParams } from '../interfaces/API/ITemplateSearchParams';
import { IUserTemplate } from "../interfaces/API/IUserTemplate";
import { ITemplateItem } from '../interfaces/API/ITemplateItem';
import { ITemplateGroup } from "../interfaces/API/ITemplateGroup";

const API_NAME = "Template/";
export class TemplateService {
  public getTemplateGroups = async (accessToken: string): Promise<ITemplateGroup[]> => {
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    try {
      const response = await axios
        .get(API_ENDPOINT + `${API_NAME}group/list`, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public searchTemplates = async (accessToken: string, body: ITemplateSearchParams): Promise<ITemplateItem[]> => {
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    
    try {
      const response = await axios
        .post(API_ENDPOINT + `${API_NAME}uploader/list`, body, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public postFavoriteTemplate = async (accessToken: string, body: IUserTemplate): Promise<IMalaApiResponse> => {;
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    
    try {
      const response = await axios
        .post(API_ENDPOINT + `${API_NAME}template/PostFavoriteTemplate`, body, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };
}