import * as React from 'react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import { createTheme, Dropdown, IDropdownOption, MessageBar, MessageBarType, TextField, ThemeProvider } from '@fluentui/react';
import _ from 'lodash';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { BlockBlobClient, BlockBlobParallelUploadOptions } from '@azure/storage-blob';
import { StorageService } from '../services/StorageService';
import { AppState } from './App';
import { TemplateService } from '../services/TemplateService';
import { IOrgTemplateCreate } from '../interfaces/API/IOrgTemplateCreate';
import { ITemplateGroup } from '../interfaces/API/ITemplateGroup';
import { TemplateItem } from '../models/TemplateItem';
import { ConfirmDialog } from './shared/ConfirmDialog';
import { Guid } from 'js-guid';
import { OrgTemplateService } from '../services/OrgTemplateService';
import { OrgTemplateGroupService } from '../services/OrgTemplateGroupService';

const modelProps = {
  isBlocking: true
};

const dialogContentProps = {
  type: DialogType.normal,
  title: 'Upload template from current document',
  closeButtonAriaLabel: 'Close',
};

const theme = createTheme({
  // You can also modify certain other properties such as fontWeight if desired
  defaultFontStyle: { fontFamily: 'sans-serif, Segoe UI, Suwannaphum' }
});

export interface IUploadTemplateDialogProps {
  hideDialog: boolean;
  toggleHideDialog: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  setState: (x: AppState) => void;
  editFormData?: TemplateItem;
  accessToken: string;  
}

export const UploadTemplateDialog: React.FunctionComponent<IUploadTemplateDialogProps> = (props) => {
  const { hideDialog, toggleHideDialog } = props;
  const [formData, setFormData] = React.useState({} as IOrgTemplateCreate);
  const [formErrors, setFormErrors] = React.useState({});

  const [hideErrors, setHideErrors] = React.useState(true);
  const [templateGroups, setTemplateGroups] = React.useState([]);
  const [orgTemplateGroups, setOrgTemplateGroups] = React.useState([]);

  const dialogTitle = {
    upload: "Upload template from current document",
    replace: "Replace template with current document"
  }

  // const options: IDropdownOption[] = [
  //   { key: 'apple', text: 'Apple' },
  //   { key: 'banana', text: 'Banana' },
  //   { key: 'lettuce', text: 'Lettuce' },
  // ];

  const templateService = new TemplateService();
  const orgTemplateService = new OrgTemplateService();

  React.useEffect(() => {
    templateService.getTemplateGroups(props.accessToken).then(groups =>{
      let options: IDropdownOption[] = groups.map((group: ITemplateGroup) => {
        return { key: group.id, text: group.name };
      });
      setTemplateGroups(options);
    });

    let orgTemplateGroupService = new OrgTemplateGroupService();
    orgTemplateGroupService.getOrgTemplateGroups(props.accessToken).then(groups =>{
      let options: IDropdownOption[] = groups.map((group: ITemplateGroup) => {
        return { key: group.id, text: group.name };
      });
      setOrgTemplateGroups(options);
    });
  }, []);

  React.useEffect(()=>{    
    if(props.editFormData){      
      let templateItem = props.editFormData;
      if(!hideDialog){
        let data: IOrgTemplateCreate = {
          description: templateItem.description,
          name: templateItem.name,
          fileUrl: templateItem.url,
          orgTemplateGroupId: templateItem.orgTemplateGroupId,
          templateGroupId: templateItem.groupId,
        };

        setFormData({
          ...data
        })
      }
    }else{
      clearForm();
    }
  }, [props.editFormData, hideDialog])

  const clearForm = () => {
    let orgTem: IOrgTemplateCreate = {
      description: "",
      name: "",
      fileUrl: "",
      orgTemplateGroupId: null,
      templateGroupId: null,
    };
    setFormData(orgTem);
  }

  const toggleErrors = () => {
    setHideErrors(!hideErrors);
  }

  const onSubmit = () => {
    // submitFormData(formData);

    // let fileUrl = new URL("https://skaitemplateuat.blob.core.windows.net/skaitemplate/%E1%9E%80%E1%9E%B7%E1%9E%85%E1%9F%92%E1%9E%85%E1%9E%9F%E1%9E%93%E1%9F%92%E1%9E%99%E1%9E%B6%E1%9E%80%E1%9E%B6%E1%9E%9A%E1%9E%84%E1%9E%B6%E1%9E%9A%20test%202.docx?sv=2021-10-04&se=2022-12-14T08%3A21%3A32Z&sr=b&sp=w&sig=%2B61VDlP7s4GiB3iCWtSpIXjDXWfjuJLu0gFhGhlidYM%3D");
    // console.log("fileUrl", fileUrl);
    // console.log("fileUrl2", fileUrl.origin + fileUrl.pathname);

    if(isFormValid()){
      console.log("form is valid");

      // if(props.replacingTemplate){
      //   setHideConfirm(false);
      //   return;
      // }

      props.setState({ hideUploadDialog: true, inProgress: true });

      getDocumentAsCompressed();
    }

    //console.log("formData", formData);
  }

  // const onConfirm = () => {
  //   props.setState({ hideUploadDialog: true, inProgress: true });

  //   getDocumentAsCompressed();
  // }

  const isFormValid = () => {
    let errors: any = {};
    let formIsValid = true;

    //Name
    if (!formData.name) {
      formIsValid = false;
      errors["name"] = "Name cannot be empty";
    }

    //Description
    if (!formData.description) {
      formIsValid = false;
      errors["description"] = "Description cannot be empty";
    }

    //Category
    if (!formData.orgTemplateGroupId) {
      formIsValid = false;
      errors["orgTemplateGroupId"] = "Org Template Group cannot be empty";
    }

    //Tags
    if (!formData.templateGroupId) {
      formIsValid = false;
      errors["templateGroupId"] = "Template Group cannot be empty";
    }

    if(!formIsValid){
      setFormErrors({ ...errors });
      return false;
    }
    return true;
  }

  const getDocumentAsCompressed = () => {
    Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 65536 /*64 KB*/ },
      function (result) {
        if (result.status == Office.AsyncResultStatus.Succeeded) {
          // If the getFileAsync call succeeded, then
          // result.value will return a valid File Object.
          const myFile = result.value;
          const sliceCount = myFile.sliceCount;
          const docdataSlices = [];
          let slicesReceived = 0, gotAllSlices = true;
          console.log("File size:" + myFile.size + " #Slices: " + sliceCount);

          // Get the file slices.
          getSliceAsync(myFile, 0, sliceCount, gotAllSlices, docdataSlices, slicesReceived);
        }
        else {
          console.log("Error:", result.error.message);
        }
      })
  }

  const getSliceAsync = (file, nextSlice, sliceCount, gotAllSlices, docdataSlices, slicesReceived) => {
    file.getSliceAsync(nextSlice, function (sliceResult) {
      if (sliceResult.status == "succeeded") {
        if (!gotAllSlices) { // Failed to get all slices, no need to continue.
          return;
        }

        // Got one slice, store it in a temporary array.
        // (Or you can do something else, such as
        // send it to a third-party server.)
        docdataSlices[sliceResult.value.index] = sliceResult.value.data;
        if (++slicesReceived == sliceCount) {
          // All slices have been received.
          file.closeAsync();
          onGotAllSlices(docdataSlices);
        }
        else {
          getSliceAsync(file, ++nextSlice, sliceCount, gotAllSlices, docdataSlices, slicesReceived);
        }
      }
      else {
        gotAllSlices = false;
        file.closeAsync();
        console.log("getSliceAsync Error:", sliceResult.error.message);
      }
    });
  }

  function onGotAllSlices(docdataSlices) {
    let docdata = [];
    let filename = `${Guid.newGuid()}${formData.name}.docx`;
    let contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    for (let i = 0; i < docdataSlices.length; i++) {
      docdata = docdata.concat(docdataSlices[i]);
    }

    const file = new File(
      [new Uint8Array(docdata)],
      filename,
      { type: contentType }
    );

    console.log("getting sas");
    var storageService = new StorageService();

    storageService.getUploadSas(props.accessToken, filename).then((res) => {
      console.log("try uploading");
      const blockBlobClient = new BlockBlobClient(res.data);
      const uploadOptions : BlockBlobParallelUploadOptions = { 
        blobHTTPHeaders: { 
          blobContentType: contentType 
        } 
      };

      blockBlobClient.uploadData(file, uploadOptions).then(value => {
        if(value._response.status === 201){
          console.log(value);
          console.log("uploaded");
          submitFormData(blockBlobClient.url);
        }
      });
    }).catch((err) => {
      //setHideDialog(true);
      props.setState({ showMessageDialog: true, dialogErrorTitle: 'Not allow', dialogErrorMessage: "You don't have permission to perform this action" });
      console.log(err);
    });

    console.log(file);
  }

  const onChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string, newValue?: string) => {
    formData[fieldName] = newValue;
    setFormData({
      ...formData
    });
  }

  const onDropdownChange = (_ev: React.FormEvent<HTMLDivElement>, fieldName: string, option?: IDropdownOption<any>, _index?: number) => {
    formData[fieldName] = option.key;
    setFormData({
      ...formData
    });
  }

  const submitFormData = (url: string) => {    
    setFormData({
      ...formData,
      fileUrl: url
    });

    let urlObj = new URL(url);

    formData["fileUrl"] = urlObj.origin + urlObj.pathname;

    if(props.editFormData){
      orgTemplateService.putUpdateOrgTemplate(props.accessToken, props.editFormData.id, formData).then((res) => {
        console.log(res);
        props.setState({ showMessageDialog: true, dialogErrorTitle: 'Success', dialogErrorMessage: "Template replaced successfully", inProgress: false, updatedTemplate: res });
      });  
    }else{  
      orgTemplateService.postSaveOrgTemplate(props.accessToken, formData).then((res) => {
        console.log(res);
        props.setState({ showMessageDialog: true, dialogErrorTitle: 'Success', dialogErrorMessage: "Template saved successfully", inProgress: false, createdTemplate: res });
      });
    }    
  }

  const renderForm = () => {
    return (
      <div>
        <TextField errorMessage={formErrors["name"]} value={formData.name} label="Name" onChange={(ev, newValue) => onChange(ev, "name", newValue)} required/>
        <TextField errorMessage={formErrors["description"]} value={formData.description} label="Description" onChange={(ev, newValue) => onChange(ev, "description", newValue)} required/>
        <Dropdown
          errorMessage={formErrors["orgTemplateGroupId"]}
          placeholder="Select an option"
          label="Org Template Group"
          selectedKey={formData.orgTemplateGroupId}
          onChange={(ev, opt, index) => onDropdownChange(ev, "orgTemplateGroupId", opt, index)}
          options={orgTemplateGroups}
          required
        />
        <Dropdown
          errorMessage={formErrors["templateGroupId"]}
          placeholder="Select an option"
          label="Template Group"
          selectedKey={formData.templateGroupId}
          onChange={(ev, opt, index) => onDropdownChange(ev, "templateGroupId", opt, index)}
          options={templateGroups}
          required
        />        
        {/* <ConfirmDialog 
            onOk={onConfirm}
            title=""
            subText="Are you sure you want to replace?"
            isHidden={hideConfirm}
            onDismiss={() => setHideConfirm(true)}
          /> */}
      </div>
    )
  }

  return (
    <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} modalProps={modelProps} dialogContentProps={{...dialogContentProps, title: (props.editFormData ? dialogTitle.replace : dialogTitle.upload)}}>
      {
        !hideErrors &&
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={toggleErrors}
          dismissButtonAriaLabel="Close"
        >
          Please fill all required fields
        </MessageBar>
      }
      <div>
        <ThemeProvider theme={theme}>
          {
            renderForm()
          }
        </ThemeProvider>
      </div>
      <DialogFooter>
        <PrimaryButton onClick={onSubmit} text={props.editFormData ? "Replace": "Upload"} />
        <DefaultButton onClick={toggleHideDialog} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};
