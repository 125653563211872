import * as React from 'react';
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';
import { createTheme, Dropdown, MessageBar, MessageBarType, TextField, ThemeProvider } from '@fluentui/react';
import _ from 'lodash';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { AppState } from './App';
import { TemplateService } from '../services/TemplateService';
import { IOrgTemplateGroupCreate } from '../interfaces/API/IOrgTemplateGroupCreate';
import { TemplateGroup } from '../models/TemplateGroup';
import { ITemplateGroupsState } from './GroupList';
import { ITemplateGroup } from '../interfaces/API/ITemplateGroup';
import { OrgTemplateGroupService } from '../services/OrgTemplateGroupService';

const modelProps = {
  isBlocking: true
};

const theme = createTheme({
  // You can also modify certain other properties such as fontWeight if desired
  defaultFontStyle: { fontFamily: 'sans-serif, Segoe UI, Suwannaphum' }
});

export interface ICreateTemplateGroupDialogProps {
  hideDialog: boolean;
  toggleHideDialog: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  updateList: (templateGroup: ITemplateGroup, isUpdate: boolean) => any;
  setState: (x: ITemplateGroupsState) => void;
  appState: (x: AppState) => void;
  editingTemplateGroup?: TemplateGroup;
  accessToken: string;
}

export const CreateTemplateGroupDialog: React.FunctionComponent<ICreateTemplateGroupDialogProps> = (props) => {
  const { hideDialog, toggleHideDialog } = props;
  const [formData, setFormData] = React.useState({} as IOrgTemplateGroupCreate);
  const [formErrors, setFormErrors] = React.useState({});
  const [hideErrors, setHideErrors] = React.useState(true);

  const templateService = new TemplateService();
  const orgTemplateService = new OrgTemplateGroupService();

  React.useEffect(()=>{    
    if(props.editingTemplateGroup){      
      let templateGroupItem = props.editingTemplateGroup;
      if(!hideDialog){
        let data: IOrgTemplateGroupCreate = {
          name: templateGroupItem.name,
          nameEn: templateGroupItem.nameEn
        };

        setFormData({
          ...data
        })
      }
    }else{
      clearForm();
    }
  }, [props.editingTemplateGroup, hideDialog])

  const clearForm = () => {
    let orgTemGr: IOrgTemplateGroupCreate = {
      nameEn: "",
      name: ""
    };
    setFormData(orgTemGr);
  }

  const toggleErrors = () => {
    setHideErrors(!hideErrors);
  }

  const onSubmit = () => {
    if(isFormValid()){
      console.log("form is valid");

      props.setState({ hideTemplateGroupDialog: true });
      props.appState({ inProgress: true });

      submitFormData();
    }
  }

  const isFormValid = () => {
    let errors: any = {};
    let formIsValid = true;

    //Name
    if (!formData.name) {
      formIsValid = false;
      errors["name"] = "Khmer Name cannot be empty";
    }

    //Description
    if (!formData.nameEn) {
      formIsValid = false;
      errors["nameEn"] = "English Name cannot be empty";
    }

    if(!formIsValid){
      setFormErrors({ ...errors });
      return false;
    }
    return true;
  }

  const onChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string, newValue?: string) => {
    formData[fieldName] = newValue;
    setFormData({
      ...formData
    });
  }  

  const submitFormData = () => {  
    if(props.editingTemplateGroup){
      orgTemplateService.putUpdateOrgTemplateGroup(props.accessToken, props.editingTemplateGroup.id, formData).then((res) => {
        console.log(res);
        
        props.updateList(res, true);

        props.appState({ showMessageDialog: true, dialogErrorTitle: 'Success', dialogErrorMessage: "Template group updated successfully", inProgress: false });
      });  
    }else{
      orgTemplateService.postSaveOrgTemplateGroup(props.accessToken, formData).then((res) => {
        console.log(res);
        props.appState({ showMessageDialog: true, dialogErrorTitle: 'Success', dialogErrorMessage: "Template group saved successfully", inProgress: false });

        props.updateList(res, false);
      });
    }    
  }

  const renderForm = () => {
    return (
      <div>
        <TextField errorMessage={formErrors["name"]} value={formData.name} label="Khmer Name" onChange={(ev, newValue) => onChange(ev, "name", newValue)} required/>
        <TextField errorMessage={formErrors["nameEn"]} value={formData.nameEn} label="English Name" onChange={(ev, newValue) => onChange(ev, "nameEn", newValue)} required/>        
      </div>
    )
  }

  return (
    <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} modalProps={modelProps}>
      {
        !hideErrors &&
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={toggleErrors}
          dismissButtonAriaLabel="Close"
        >
          Please fill all required fields
        </MessageBar>
      }
      <div>
        <ThemeProvider theme={theme}>
          {
            renderForm()
          }
        </ThemeProvider>
      </div>
      <DialogFooter>
        <PrimaryButton onClick={onSubmit} text={props.editingTemplateGroup ? "Update": "Save"} />
        <DefaultButton onClick={toggleHideDialog} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};
